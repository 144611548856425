<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">
        <img src="@/assets/logo.png" alt="Logo IPSSOMA" class="img-fluid" width="200" />
      </router-link>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02"
        aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse justify-content-end" id="navbarTogglerDemo02">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="nav-link" to="/">Inicio </router-link>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/#graduates">Cursos</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/#nosotros">Nosotros</a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" href="/#graduates">Diplomados</a>
          </li> -->
          <li class="nav-item">
            <a class="nav-link" href="/#certificado">Certificación</a>
          </li>
          <!-- <li class="nav-item">
            <router-link class="nav-link" to="/consultoria">Consultoría
            </router-link>
          </li> -->
          <li class="nav-item">
            <a class="nav-link" href="/#redes">Contacto</a>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/news">Noticias </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/iniciar-sesion" target='_blank'>Aula Virtual
            </router-link>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              Aplicaciones
            </a>
            <div class="dropdown-menu dropdown-menu-custom" aria-labelledby="navbarDropdown">
              <!-- <a class="dropdown-item dropdown-item-custom" href="https://universidad.promolider.org/">Promolíder</a>
              <router-link class="dropdown-item" to="/masterclass">MasterClass
              </router-link>
              <a class="dropdown-item" href="https://jobs.ipssoma.com">Bolsa de Trabajo</a>

              <div class="dropdown-divider"></div> -->
              <a class="dropdown-item" href="https://crm.inprocal.org" target="_blank">ADMIN</a>
            </div>
          </li>
          <!-- <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="cartDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img src="../../../assets/cart4.svg" alt="">
            </a>
            <div
              class="dropdown-menu dropdown-menu-right"
              aria-labelledby="navbarDropdown"
            >
              <div v-for="course in cartProducts" :key="course.id" :course="course" :mode="mode" class="cart-product">
                <routerLink to="/" class="dropdown-item dropdown-item-cart">
                <span @click="goToCourse()">{{ course.nombre }}</span>
                </routerLink>
                <button class="btn btn-warning px-4 rounded-lg deleteCartProductBtn" @click="deleteCartProduct()">X</button>
              </div>
              <div class="dropdown-divider"></div>
              <div class="row" id="btnsCart">
                <div class="col-auto"> <button class="btn btn-warning px-4 rounded-lg " @click="emptyCart()">Vaciar Carrito</button></div>
                <div class="col-auto"> <button class="btn btn-warning px-4 rounded-lg " @click="openPaymentModal()">Ir a pagar</button></div>
              </div>
              
            </div>
          </li> -->
        </ul>
      </div>
    </div>
  </nav>
</template>
<style>
.dropdown-menu>a {
  text-decoration: none !important;
  color: rgba(0, 0, 0, 0.5);
}

.dropdown-item-cart {
  display: flex;
  align-items: center;
}

html {
  scroll-behavior: smooth;
}

#cartDropdown {
  float: left;
}

.cart-product {
  display: flex;
  flex-direction: row;
}

.cart-product a {
  text-decoration: none !important;
  color: rgba(0, 0, 0, 0.5);
}

.deleteCartProductBtn {
  margin: 10px;
}

#btnsCart .col-auto button {
  margin: 10px;
}
</style>
<script>
import eventBus from "../../../main"
import { useShoppingCart } from '../../../store/shoppingCart';

export default {
  data() {
    return {
    }
  },
  computed: {
    cartProducts() {
      return useShoppingCart().$state.cartProducts
    }
  },
  props: {
    course: {
      type: Object
    },
    mode: {
      type: String,
      default: "private",
    },
    // showPrivateNavbar: {
    //   type: Boolean,
    //   default: false,
    // },
    width: Number,
    height: {
      type: String,
      default: "100%",
    },

  },
  methods: {
    openCartPay() {
      eventBus.$emit("openCartPay")
    },
    goToCourse() {
      localStorage.setItem("mode", this.mode);
      var type = this.getProductType(this.course.tipo_producto_id) == null
        ? "oferta"
        : this.getProductType(this.course.tipo_producto_id);
      if (type == "oferta") {
        const config = {
          slug: this.course.slug,
        };
        this.$router.push({
          name: "oferta",
          params: config,
        });
      }
      else {
        const config = {
          type: type,
          slug: this.course.slug,
        };
        this.$router.push({
          name: "producto",
          params: config,
        });
      }
    },
    getProductType(id) {
      if (id == 1) {
        return "curso";
      }
      else if (id == 2) {
        return "diplomado";
      }
    },
    deleteCartProduct() {
      const deletedProduct = this.course
      useShoppingCart().deleteCartItem(deletedProduct)
    },
    emptyCart() {
      useShoppingCart().emptyCart()
    }
  },
}
</script>
