var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navbar navbar-expand-lg navbar-light bg-light"},[_c('div',{staticClass:"container-fluid"},[_c('router-link',{staticClass:"navbar-brand",attrs:{"to":"/"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/logo.png"),"alt":"Logo IPSSOMA","width":"200"}})]),_vm._m(0),_c('div',{staticClass:"collapse navbar-collapse justify-content-end",attrs:{"id":"navbarTogglerDemo02"}},[_c('ul',{staticClass:"navbar-nav"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/"}},[_vm._v("Inicio ")])],1),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/news"}},[_vm._v("Noticias ")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/iniciar-sesion","target":"_blank"}},[_vm._v("Aula Virtual ")])],1),_vm._m(5)])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbarTogglerDemo02","aria-controls":"navbarTogglerDemo02","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"navbar-toggler-icon"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/#graduates"}},[_vm._v("Cursos")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/#nosotros"}},[_vm._v("Nosotros")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/#certificado"}},[_vm._v("Certificación")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/#redes"}},[_vm._v("Contacto")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item dropdown"},[_c('a',{staticClass:"nav-link dropdown-toggle",attrs:{"href":"#","id":"navbarDropdown","role":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v(" Aplicaciones ")]),_c('div',{staticClass:"dropdown-menu dropdown-menu-custom",attrs:{"aria-labelledby":"navbarDropdown"}},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"https://crm.inprocal.org","target":"_blank"}},[_vm._v("ADMIN")])])])
}]

export { render, staticRenderFns }