<template>
  <div class="bg-main-blue footer" id="redes">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-4 col-sm-12 mt-5 c-1">
          <div class="row mt-4 ml-1">
            <img src="@/assets/logo-footer.png" alt="logo" width="200"/>
          </div>
          <div class="row">
            <p class="text-white my-4 text-footer text-justify font">
              Somos una institución con modalidad E-learning, que genera
              educación de calidad, formando especialistas en el área de
              Seguridad y Salud Ocupacional, Medio Ambiente y Calidad.
            </p>
          </div>
          <div class="row fila-iconos-redes container-fluid text-right mt-3">
            <a href="https://www.facebook.com/ipssoma/" target="_blank">
              <img src="@/assets/fb_1@4x.png" alt="" style="max-width: 40px;">
            </a>
            <a href="https://www.linkedin.com/company/instituto-peruano-ssoma/" target="_blank">
              <img src="@/assets/in_1@4x.png" alt="" style="max-width: 40px;">
            </a>
            <a href="https://www.instagram.com/ipssoma" target="_blank">
              <img src="@/assets/instagram_1@4x.png" alt="" style="max-width: 40px">
            </a>
            <a href="https://www.youtube.com/channel/UCvYRjy5-aijIB3tZK-lE6gg" target="_blank">
              <img src="@/assets/youtube_1@4x.png" alt="" style="max-width: 40px">
            </a>
            <a href="https://www.tiktok.com/@ipssoma" target="_blank">
              <img src="@/assets/tiktok_1@4x.png" alt="" style="max-width: 40px;">
            </a>
          </div>
        </div>
        <div class="col-12 col-md-4 col-sm-12">
          <div id="formulario-iso" class="consultoria_formulario" action="cotizacion-client.php" method="post">
            <p class="text-white mt-5 mb-4 c-2 sub-titulo-footer text-justify font">
              Cotización o consultaría
            </p>
              <input v-model="form.user" type="text" class="form-control font" placeholder="Nombres y Apellidos"
                required />
              <input v-model="form.phone" type="text" class="form-control font" placeholder="Celular" required />
              <input v-model="form.email" type="e-mail" class="form-control font" placeholder="E-mail" required />
              <textarea v-model="form.message" placeholder="Mensaje" class="form-control font" required></textarea>
              <button class="btn bg-buttons mb-5 font text-white" @click="sendQuery()" data-toggle="modal" data-target="#exampleModal">
              Enviar
              </button>
              <!-- Modal -->
              <div class="modal" tabindex="-1" role="dialog">
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">Mensaje</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  <div class="modal-body">
                    <p>En breve un miembro del equipo de soporte se pondra en contacto con usted.</p>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 col-sm-12">
          <div class="contactenos_consultoria">
            <p class="text-white mt-5 mb-4 c-3 sub-titulo-footer text-justify font">
              Contáctenos
            </p>
            <div class="fila-contact">
              <img src="@/assets/Ubicacion.png" alt="Ubicacion IpsSoma" />
              <p class="text-white text-justify font">
                Av. La Fontana #440 – Centro Comercial “La Rotonda II” - Oficina
                2086. La Molina, Lima - Perú
              </p>
            </div>
            <div class="fila-contact">
              <img src="@/assets/Correo.png" alt="Correo IpsSoma" />
              <p class="text-white font">Inprocal.org@gmail.com</p>
            </div>
            <div class="fila-contact">
              <img src="@/assets/telefono.png" alt="Telefono IpsSoma" />
              <p class="text-white text-justify font">
                Lic. Gladys Luján, Coordinadora académica.<br />
                Teléfono: 901 874 235
                <a href="https://wa.link/mgncot"> - Whatsapp </a>
              </p>
            </div>
            <div class="fila-contact">
              <img src="@/assets/telefono.png" alt="Telefono IpsSoma" />
              <p class="text-white text-justify font">
                Lic. Nancy Férnandez, Asesor educativo.<br />
                Teléfono: 928 866 727
                <a href="https://wa.link/nhxcc9"> - Whatsapp </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-snackbar
      v-model="snackbar"
      color="#ffc107"
    >
      <p class="text-dark m-0 p-0">Enviado</p>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="#000"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          x
        </v-btn>
      </template>
    </v-snackbar>
    <div class="text-white text-center bg-secondary" style="width: 100vw;">
      © 2022 promolíder.org Copyright.
    </div>
    <BottomChat></BottomChat>
  </div>
</template>
<script>
import BottomChat from "@/components/public/SocialChat/Index.vue";
export default {
  data() {
    return {
      form: {
        user: "",
        phone: "",
        email: "",
        message: "",
        snackbar: false,
        msg: "",
      },
    };
  },
  components: {
    BottomChat,
  },
  methods: {
    async sendQuery() {
      // if(this.validateFields()){
        const form = {
          fullname: this.form.user,
          phone: this.form.phone,
          email: this.form.email,
          message: this.form.message,
        };
        await this.$axios.post("/public/save-queries", form).then((response) => {
          console.log(response);
          this.form.user = "";
          this.form.phone = "";
          this.form.email = "";
          this.form.message = "";
          this.msg = "Enviado";
          this.snackbar = true;
        });
      // }
    },
    validateFields(){
      if(this.form.user == "" || this.form.user.length < 3){
        this.msg = "Ingrese su nombre correctamente";
        this.snackbar = true;
        return false;
      }
      if(this.form.phone == "" || this.form.phone < 900000000){
        this.msg = "Ingrese su número telefónico correctamente";
        this.snackbar = true;
        return false;
      }
      if(this.form.email == ""){
        this.msg = "Ingrese su correo";
        this.snackbar = true;
        return false;
      }
      return true;
    }
  },
};
</script>
<style>
.font {
  font-family: Roboto !important;
}

.text-justify {
  text-align: justify !important;
}

.fila-iconos-redes {
  gap: 25px;
}

.redes {
  text-align: justify;
}

.footer .fila-contact {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.footer .fila-contact img {
  width: 30px;
  height: 30px;
}

.footer .consultoria_formulario {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footer input {
  height: 40px;
}

.footer textarea,
.footer input {
  border: none;
  outline: none;
  border-radius: 10px;
  padding-left: 10px;
}

.footer .text-footer {
  margin-right: 15px;
}

.footer .consultoria_formulario button {
  width: 100px;
}

.footer .sub-titulo-footer {
  font-size: 24px;
}

.footer .fila-contact {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.footer .fila-contact img {
  width: 30px;
  height: 30px;
}
</style>
